import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';
import {type PaymentsModel} from '../models/payments';

type ServerMessage = {
	message: string;
};

class PaymentsService {
	get = async (restId: string | undefined): Promise<ServiceResponse<ResponseData<PaymentsModel[]>>> => {
		try {
			const response = restId ? await axios.post<ResponseData<PaymentsModel[]>>(endpoints.payments,
				{restId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				}) : await axios.get<ResponseData<PaymentsModel[]>>(endpoints.payments,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});

			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default PaymentsService;
