import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';
import {type RestaurantStatus, type RestaurantModel, type RestaurantAvailability, type RestaurantHoliday, type RestaurantRemHoliday, type RestaurantTimeSlot} from '../models/restaurants';

type ServerMessage = {
	message: string;
};

class RestaurantsService {
	get = async (): Promise<ServiceResponse<ResponseData<RestaurantModel[]>>> => {
		try {
			const response = await axios.get<ResponseData<RestaurantModel[]>>(endpoints.restaurants,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	status = async (pay: RestaurantStatus): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.restaurantstatus,
				pay,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	availability = async (pay: RestaurantAvailability): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.restaurantavail,
				pay,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	holiday = async (pay: RestaurantHoliday): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.restaurantholi,
				pay,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	remholiday = async (pay: RestaurantRemHoliday): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.restaurantremholi,
				pay,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	times = async (pay: RestaurantTimeSlot): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.restauranttimes,
				pay,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default RestaurantsService;

