import React, {useEffect, useRef, useState} from 'react';
import './Expenses.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import ExpensesService from '../../services/expenses';
import {type ExpensesModel} from '../../models/expenses';
import ExpenseCategoryService from '../../services/expensecategory';
import {type ExpenseCategoryModel} from '../../models/expensecategory';
import RadioButton from '../../components/RadioButton/RadioButton';
import {type RestaurantModel} from '../../models/restaurants';
import RestaurantsService from '../../services/restaurants';
import DatePicker, {type DatePickerHandle} from '../../components/DatePicker/DatePicker';
import {useAuthContext} from '../../context/authContext';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Expenses = () => {
	const authRes = useAuthContext();

	const [isAddExp, setAddExp] = useState<boolean>(false);
	const [data, setData] = useState<ExpensesModel[]>([]);
	const [expData, setExpData] = useState<ExpenseCategoryModel[]>([]);
	const [resData, setResData] = useState<RestaurantModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [isRestLoading, setRestLoading] = useState<boolean>(true);
	const [selMainRest, setSelMainRest] = useState<number>(-1);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const billId = useRef<TextFieldHandle>(null);
	const purpose = useRef<TextFieldHandle>(null);
	const spended = useRef<TextFieldHandle>(null);
	const [selCate, setSelCat] = useState<number>(-1);
	const [selRest, setSelRest] = useState<number>(-1);
	const dateRef = useRef<DatePickerHandle>(null);

	useEffect(() => {
		void fetchExpCat();
		void fetchRest();
	}, []);

	async function fetchRest() {
		if (!isRestLoading) {
			setRestLoading(true);
		}

		await new RestaurantsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setResData(val.res!.data!);
			}
		}).finally(() => {
			setRestLoading(false);
		});
	}

	async function fetchExpCat() {
		if (!isLoading) {
			setLoading(true);
		}

		await new ExpenseCategoryService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setExpData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	async function fetchExp(i: number) {
		if (!isLoading) {
			setLoading(true);
		}

		await new ExpensesService().get(resData[i]._id).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddExp(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = billId.current!.validate();
		const field2 = purpose.current!.validate();
		const field3 = spended.current!.validate();

		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, field1.msg);
		} else if (!field2.isValid) {
			snackBarRibbon.current!.trigger(true, field2.msg);
		} else if (!field3.isValid) {
			snackBarRibbon.current!.trigger(true, field3.msg);
		} else if (dateRef.current!.getValue() === undefined) {
			snackBarRibbon.current!.trigger(true, 'Select the date');
		} else if (selCate === -1) {
			snackBarRibbon.current!.trigger(true, 'Select the expense category');
		} else {
			if (!isLoading) {
				setLoading(true);
			}

			await new ExpensesService().create({
				restId: selRest === -1 ? null : resData[selRest]._id,
				billId: billId.current!.getValue()!,
				date: dateRef.current!.getValue()!,
				purpose: purpose.current!.getValue()!,
				expCatId: expData[selCate]._id,
				spended: parseFloat(spended.current!.getValue()!),
			}).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchExp(selMainRest);

					snackBarRibbon.current!.trigger(false, val.res!.message);

					billId.current!.clearInput();
					purpose.current!.clearInput();
					spended.current!.clearInput();
				}
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddExp) ? 'center' : 'start',
	};

	return (
		<div className='Expenses'>
			<div className='rest-main-left-container' style={{width: '20%'}}>
				{isRestLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index-load'>00</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
							<Space size={10} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
						</div>
					</div>;
				}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={resData} adapter={function (i, item): JSX.Element {
					const itemData = item as RestaurantModel;
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
							<Space size={5} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-subtitle'>{itemData.address}</label>
						</div>
					</div>;
				}} onSelectItem={i => {
					if (selMainRest !== i) {
						setAddExp(false);
						setItemIndex(-1);
						setSelMainRest(i);
						void fetchExp(i);
					}
				}} selItemIndex={selMainRest} adapterHeight={80} />)}
			</div>
			<div className='Expenses-left-panel' style={{width: '20%'}}>
				<div className='Expenses-left-tools'>
					<label className='Expenses-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Expense'}></OutlineButton>
				</div>
				{isLoading ? (<div className='Expenses-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Expenses-list-adapter'>
							<label className='Expenses-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Expenses-list-adapter-card'>
								<label className='Expenses-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={65} />
				</div>) : (<div className='Expenses-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as ExpensesModel;
						return <div className='Expenses-list-adapter'>
							<label className='Expenses-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Expenses-list-adapter-card'>
								<label className='Expenses-list-adapter-perc'>{itemData.billId}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Expenses-list-adapter-update'>{itemData.spended}</label>
								<Space size={5} isAutoResize={false}></Space>
								<label className='Expenses-list-adapter-update'>{itemData.date}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddExp(false);
						setItemIndex(i);
					}} adapterHeight={65} />
				</div>)}
			</div>
			<div className='Expenses-right-panel' style={{width: '60%'}}>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='Expenses-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddExp && <label className='Expenses-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='Expenses-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>{data[selItemIndex].billId}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>{data[selItemIndex].category.catName.toUpperCase()}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>{data[selItemIndex].date}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>{data[selItemIndex].purpose}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>{`Expense Amount : ${data[selItemIndex].spended}`}</label>
						<Space size={15} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>Restaurant</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>{data[selItemIndex].restaurant.name}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>{data[selItemIndex].restaurant.address}</label>
					</div>}
					{isAddExp && <div>
						<label className='Expenses-detail-perc'>Select the expense category</label>
						<Space size={15} isAutoResize={false}></Space>
						{isLoading ? (<div className='listview-wrapper'>
							<ListView dummy={3} adapter={function (i, item): JSX.Element {
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index-load'>00</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card-load'>
										<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
									</div>
								</div>;
							}} selItemIndex={-1} adapterHeight={35} />
						</div>) : (<div className='listview-wrapper'>
							<ListView data={expData} adapter={function (i: number, item: ExpenseCategoryModel | number): JSX.Element {
								const itemData = item as ExpenseCategoryModel;
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card'>
										<label className='listview-item-adapter-title'>{itemData.catName}</label>
										<RadioButton title='' index={i} selIndex={selCate} onClick={(r: number) => {
											if (!isLoading) {
												setSelCat(r);
											}
										}}></RadioButton>
										<Space size={5} isAutoResize={true}></Space>
									</div>
								</div>;
							}} adapterHeight={35} selItemIndex={selCate} onSelectItem={(i: number) => {
								if (!isLoading) {
									setSelCat(i);
								}
							}} />
						</div>)}
						<Space size={15} isAutoResize={false}></Space>
						<label className='Expenses-detail-perc'>If this expense related to any restaurant then only select otherwise not select</label>
						<Space size={15} isAutoResize={false}></Space>
						{isLoading ? (<div className='listview-wrapper'>
							<ListView dummy={3} adapter={function (i, item): JSX.Element {
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index-load'>00</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card-load'>
										<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
									</div>
								</div>;
							}} selItemIndex={-1} adapterHeight={35} />
						</div>) : (<div className='listview-wrapper'>
							<ListView data={resData} adapter={function (i: number, item: RestaurantModel | number): JSX.Element {
								const itemData = item as RestaurantModel;
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card'>
										<label className='listview-item-adapter-title'>{itemData.name}</label>
										<RadioButton title='' index={i} selIndex={selRest} onClick={(r: number) => {
											if (!isLoading) {
												setSelRest(r);
											}
										}}></RadioButton>
										<Space size={5} isAutoResize={true}></Space>
									</div>
								</div>;
							}} adapterHeight={35} selItemIndex={selRest} onSelectItem={(i: number) => {
								if (!isLoading) {
									setSelRest(i);
								}
							}} />
						</div>)}
						<Space size={15} isAutoResize={false}></Space>
						<DatePicker ref={dateRef} label={'Bill Date'} hasPast={true}></DatePicker>
						<Space size={15} isAutoResize={false}></Space>
						<div className='Expenses-list-container-row'>
							<TextField ref={billId} isLoading={isLoading}
								label={'Enter the bill id'}
								inputType={TextFieldInputTypes.name}></TextField>
							<Space size={15} isAutoResize={false}></Space>
							<TextField ref={purpose} isLoading={isLoading}
								label={'Enter the purpose'}
								inputType={TextFieldInputTypes.name}></TextField>
							<Space size={15} isAutoResize={false}></Space>
							<TextField ref={spended} isLoading={isLoading}
								label={'Enter the amount'}
								inputType={TextFieldInputTypes.floatnumber}></TextField>
						</div>
						<Space size={15} isAutoResize={false}></Space>
						<Button isLoading={isLoading} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Expenses;
