import React, {createContext} from 'react';
import {type SitingsModel} from '../models/sitings';
import SitingsService from '../services/sitings';

export type SitingContextResult = {
	data: SitingsModel[];
	isLoading: boolean;
	fetchData: () => Promise<SitingsModel[]>;
	setLoading: (b: boolean) => void;
};

type SitingProps = {
	children: React.ReactNode;
};

export const SitingContext = createContext<SitingContextResult>({
	data: [],
	isLoading: true,
	async fetchData() {
		return [];
	},
	setLoading() {
		//
	},
});

type SitingState = {
	data: SitingsModel[];
	isLoading: boolean;
};

export class SitingProvider extends React.Component<SitingProps, SitingState> {
	constructor(props: SitingProps) {
		super(props);

		this.state = {
			data: [],
			isLoading: true,
		};
	}

	async componentDidMount() {
		await this.fetchRest();
	}

	fetchRest = async () => {
		if (localStorage.getItem('AUT-GENERAL-MANAGER')) {
			if (!this.state.isLoading) {
				this.setState({isLoading: true});
			}

			await new SitingsService().get().then(val => {
				if (!val.hasError && val.res !== undefined) {
					if (val.res.data) {
						this.setState({data: val.res.data});
					}
				}
			}).finally(() => {
				this.setState({isLoading: false});
			});
		}
	};

	render(): React.ReactNode {
		return (
			<SitingContext.Provider
				value={{
					data: this.state.data,
					fetchData: async () => {
						await this.fetchRest();
						return this.state.data;
					},
					setLoading: (l: boolean) => {
						this.setState({
							isLoading: l,
						});
					},
					isLoading: this.state.isLoading,
				}}>
				{this.props.children}
			</SitingContext.Provider>
		);
	}
}
