import React, {useEffect, useRef, useState} from 'react';
import './Suppliers.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView, {type ListViewHandle} from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import SuppliersService from '../../services/suppliers';
import {type SuppliersModel} from '../../models/suppliers';
import GoogleMapMain, {type MapHandle} from '../../components/GoogleMap/GoogleMapMain';
import {type RestaurantModel} from '../../models/restaurants';
import RestaurantsService from '../../services/restaurants';
import ListSlots, {type ListSlotsHandle} from '../../components/ListSlots/ListSlots';
import CheckBox from '../../components/CheckBox/CheckBox';
import {useAuthContext} from '../../context/authContext';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Suppliers = () => {
	const authRes = useAuthContext();

	const [isAddSupp, setAddSupp] = useState<boolean>(false);
	const [data, setData] = useState<SuppliersModel[]>([]);
	const [reData, setReData] = useState<RestaurantModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [isRestLoading, setRestLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const name = useRef<TextFieldHandle>(null);
	const address = useRef<TextFieldHandle>(null);
	const mapLocation = useRef<MapHandle>(null);
	const restRef = useRef<ListViewHandle>(null);
	const [selRest, setSelRest] = useState<number[]>([]);
	const [selMainRest, setSelMainRest] = useState<number>(-1);
	const contacts = useRef<ListSlotsHandle>(null);

	useEffect(() => {
		void fetchRest();
	}, []);

	async function fetchRest() {
		if (!isRestLoading) {
			setRestLoading(true);
		}

		await new RestaurantsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setReData(val.res!.data!);
			}
		}).finally(() => {
			setRestLoading(false);
		});
	}

	async function fetchSuppliers(i: number) {
		if (!isLoading) {
			setLoading(true);
		}

		await new SuppliersService().get(reData[i]._id).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddSupp(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const nameField = name.current!.validate();
		const addressField = address.current!.validate();

		if (!nameField.isValid) {
			snackBarRibbon.current!.trigger(true, nameField.msg);
		} else if (!addressField.isValid) {
			snackBarRibbon.current!.trigger(true, addressField.msg);
		} else if (mapLocation.current!.position().length === 0) {
			snackBarRibbon.current!.trigger(true, 'Select the location');
		} else {
			if (!isLoading) {
				setLoading(true);
			}

			await new SuppliersService().create({
				restIds: reData.filter((_, i) => selRest.includes(i)).map(v => v._id),
				name: name.current!.getValue(),
				address: address.current!.getValue(),
				coordinates: mapLocation.current!.position(),
				contacts: contacts.current!.data(),
			}).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchSuppliers(selMainRest);

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				name.current!.clearInput();
				address.current!.clearInput();
			}).finally(() => {
				setLoading(false);
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddSupp) ? 'center' : 'start',
	};

	return (
		<div className='Suppliers'>
			<div className='rest-main-left-container' style={{width: '20%'}}>
				{isRestLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index-load'>00</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
							<Space size={10} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
						</div>
					</div>;
				}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={reData} adapter={function (i, item): JSX.Element {
					const itemData = item as RestaurantModel;
					return <div className='rest-main-listview'>
						<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
						<Space size={5} isAutoResize={true}></Space>
						<div className='rest-main-listitem-adapter-card'>
							<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
							<Space size={5} isAutoResize={true}></Space>
							<label className='rest-main-listitem-adapter-subtitle'>{itemData.address}</label>
						</div>
					</div>;
				}} onSelectItem={i => {
					if (selMainRest !== i) {
						setAddSupp(false);
						setItemIndex(-1);
						setSelMainRest(i);
						void fetchSuppliers(i);
					}
				}} selItemIndex={selMainRest} adapterHeight={80} />)}
			</div>
			{selMainRest !== -1 && <div className='Suppliers-left-panel' style={{width: '20%'}}>
				<div className='Suppliers-left-tools'>
					<label className='Suppliers-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Supplier'}></OutlineButton>
				</div>
				{isLoading || isRestLoading ? (<div className='Suppliers-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Suppliers-list-adapter'>
							<label className='Suppliers-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Suppliers-list-adapter-card'>
								<label className='Suppliers-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={40} />
				</div>) : (<div className='Suppliers-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as SuppliersModel;
						return <div className='Suppliers-list-adapter'>
							<label className='Suppliers-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Suppliers-list-adapter-card'>
								<label className='Suppliers-list-adapter-perc'>{itemData.name}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddSupp(false);
						setItemIndex(i);
					}} adapterHeight={40} />
				</div>)}
			</div>}
			<div className='Suppliers-right-panel' style={{width: '60%'}}>
				<div className='Suppliers-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddSupp && <label className='Suppliers-right-empty-content'>No items selected</label>}
					{selMainRest !== -1 && selItemIndex !== -1 && <div>
						<label className='Suppliers-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Suppliers-detail-perc'>{data[selItemIndex].name}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Suppliers-detail-perc'>{data[selItemIndex].address}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Suppliers-detail-perc'>{data[selItemIndex].contacts.join(', ')}</label>
						<Space size={10} isAutoResize={false}></Space>
						<div className='listview-wrapper' style={{height: '300px'}}>
							<ListView data={data[selItemIndex].restaurants} adapter={function (i: number, item: {
								_id: string;
								name: string;
								address: string;
							} | number): JSX.Element {
								const itemData = item as {
									_id: string;
									name: string;
									address: string;
								};
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card' style={{flexDirection: 'column'}}>
										<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
										<Space size={5} isAutoResize={false}></Space>
										<label className='listview-item-adapter-title'>{`${itemData.address}`}</label>
									</div>
								</div>;
							}} adapterHeight={65} />
						</div>
					</div>}
					{isAddSupp && <div>
						<Button isLoading={isLoading} label={'Save'} onClick={onSave}></Button>
						<Space size={10} isAutoResize={false}></Space>
						<TextField ref={name} isLoading={isLoading}
							label={'Enter the supplier name'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={10} isAutoResize={false}></Space>
						<label className='Suppliers-detail-perc'>Supplier Contacts</label>
						<Space size={10} isAutoResize={false}></Space>
						<div className='addrest-container' style={{padding: '1%', height: '200px'}}>
							<ListSlots ref={contacts} isLoading={isLoading} title={'Enter the number'} placeHold={'I.e: +007647343'}></ListSlots>
						</div>
						<Space size={10} isAutoResize={false}></Space>
						<label className='Suppliers-detail-perc'>Restaurants (this suppliers available only to this selected restaurants, if this supplier is private no need to select)</label>
						<Space size={10} isAutoResize={false}></Space>
						{(isLoading ? (<div className='listview-wrapper'>
							<ListView dummy={3} adapter={function (i, item): JSX.Element {
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index-load'>00</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card-load'>
										<label className='listview-item-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
									</div>
								</div>;
							}} selItemIndex={-1} adapterHeight={35} />
						</div>) : (<div className='listview-wrapper'>
							<ListView ref={restRef} data={reData} adapter={function (i: number, item: RestaurantModel | number): JSX.Element {
								const itemData = item as RestaurantModel;
								return <div className='listview-item-adapter'>
									<label className='listview-item-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='listview-item-adapter-card'>
										<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
										<CheckBox title='' initVal={selRest.includes(i)} ovrVal={selRest.includes(i)} onClick={(b: boolean) => {
											if (!isLoading) {
												if (selRest.includes(i)) {
													setSelRest(selRest.filter(v => v !== i));
												} else {
													setSelRest([...selRest, i]);
												}
											}
										}}></CheckBox>
										<Space size={5} isAutoResize={true}></Space>
									</div>
								</div>;
							}} adapterHeight={35} selItemIndex={selRest} onSelectItem={(i: number) => {
								if (!isLoading) {
									if (selRest.includes(i)) {
										setSelRest(selRest.filter(v => v !== i));
									} else {
										setSelRest([...selRest, i]);
									}
								}
							}} />
						</div>))}
						<Space size={10} isAutoResize={false}></Space>
						<TextField ref={address} isLoading={isLoading}
							label={'Enter the supplier address'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<GoogleMapMain ref={mapLocation} isLoading={isLoading} height='250px' onMark={(s: string) => {
							address.current!.initValue(s);
						}}></GoogleMapMain>
						<Space size={50} isAutoResize={false}></Space>
					</div>}
				</div>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			</div>
		</div >
	);
};

export default Suppliers;
