// Const base = 'https://localhost:4040/api';
const base = 'https://api.tasteofindia.nz/api';

const endpoints = {
	basews: 'https://api.tasteofindia.nz',
	local: 'https://localhost:5312',
	register: `${base}/general-manager/send-otp`,
	verifyOtp: `${base}/general-manager/verify-otp`,
	userDetails: `${base}/general-manager/user-details`,
	taxes: `${base}/general-manager/taxes`,
	addtax: `${base}/general-manager/tax`,
	contacts: `${base}/general-manager/contacts`,
	sendotpcontact: `${base}/general-manager/contact`,
	verifyotpcontact: `${base}/general-manager/verify-contact`,
	resendcontactotp: `${base}/general-manager/resend-contact-otp`,
	cities: `${base}/general-manager/cities`,
	addcity: `${base}/general-manager/city`,
	cityavail: `${base}/general-manager/city-availability`,
	deliverycharges: `${base}/general-manager/delivery-charges`,
	deliverycharge: `${base}/general-manager/delivery-charge`,
	conditions: `${base}/general-manager/conditions`,
	sitings: `${base}/general-manager/sitings`,
	siting: `${base}/general-manager/siting`,
	linksitings: `${base}/general-manager/link-sitings`,
	restauranttypes: `${base}/general-manager/restaurant-types`,
	plugins: `${base}/general-manager/plugins`,
	restaurants: `${base}/general-manager/restaurants`,
	addrestaurant: `${base}/general-manager/restaurant`,
	restaurantstatus: `${base}/general-manager/restaurant-status`,
	restaurantavail: `${base}/general-manager/restaurant-availability`,
	restauranttimes: `${base}/general-manager/restaurant-timeslots`,
	restaurantholi: `${base}/general-manager/restaurant-holiday`,
	restaurantremholi: `${base}/general-manager/restaurant-remove-holiday`,
	assignplugin: `${base}/general-manager/assign-plugin`,
	plugindistribute: `${base}/general-manager/plugin-distribute`,
	plugintrigger: `${base}/general-manager/trigger-plugin`,
	categories: `${base}/general-manager/categories`,
	category: `${base}/general-manager/category`,
	customizations: `${base}/general-manager/customizations`,
	customization: `${base}/general-manager/customization`,
	addons: `${base}/general-manager/addons`,
	addon: `${base}/general-manager/addon`,
	menus: `${base}/general-manager/menus`,
	menu: `${base}/general-manager/menu`,
	materials: `${base}/general-manager/materials`,
	material: `${base}/general-manager/material`,
	coalitions: `${base}/general-manager/coalitions`,
	fooditem: `${base}/general-manager/food-item`,
	fooditems: `${base}/general-manager/food-items`,
	foodavail: `${base}/general-manager/food-item-availability`,
	foodstock: `${base}/general-manager/food-item-stock`,
	foodoffer: `${base}/general-manager/food-item-offer`,
	fooddelist: `${base}/general-manager/food-item-delist`,
	findfooditems: `${base}/general-manager/find-food-items`,
	coupons: `${base}/general-manager/coupons`,
	addcoupon: `${base}/general-manager/coupon`,
	accesscontrols: `${base}/general-manager/access-controls`,
	accessapprove: `${base}/general-manager/approve-access`,
	accessupdate: `${base}/general-manager/update-access`,
	lorul: `${base}/general-manager/link-or-unlink`,
	changes: `${base}/general-manager/changes`,
	approvechange: `${base}/general-manager/approve-change`,
	changeslog: `${base}/general-manager/changes-log`,
	suppliers: `${base}/general-manager/suppliers`,
	supplier: `${base}/general-manager/supplier`,
	purchases: `${base}/general-manager/purchases`,
	purchase: `${base}/general-manager/purchase`,
	purchasestock: `${base}/general-manager/update-purchase-stock`,
	expenses: `${base}/general-manager/expenses`,
	expense: `${base}/general-manager/expense`,
	expensecategories: `${base}/general-manager/expense-categories`,
	payments: `${base}/general-manager/payments`,
	orders: `${base}/general-manager/orders`,
	orderhistory: `${base}/general-manager/orders-history`,
	acceptorrej: `${base}/general-manager/accept-or-reject-order`,
	takestate: `${base}/general-manager/takeaway-status`,
	takecompl: `${base}/general-manager/takeaway-delivered`,
	assigndel: `${base}/general-manager/assign-delivery`,
	orderhistorydet: `${base}/general-manager/order-details`,
	cancresv: `${base}/general-manager/canceled-reservations`,
	deliveries: `${base}/general-manager/deliveries`,
	dirposgetcarts: `${base}/general-manager/direct-carts`,
	dirposcrecart: `${base}/general-manager/direct-create-cart`,
	dirposaddtocart: `${base}/general-manager/direct-add-to-cart`,
	dirposgetcartdet: `${base}/general-manager/direct-cart-details`,
	dirposupcartqty: `${base}/general-manager/update-cart-qty`,
	dirposdelcartitem: `${base}/general-manager/delete-cart-item`,
	dirposdelcart: `${base}/general-manager/delete-cart`,
	dirposaddsit: `${base}/general-manager/add-siting-cart`,
	dirposaddxtrachar: `${base}/general-manager/add-extra-charges-cart`,
	paymodes: `${base}/general-manager/payment-modes`,
	delservs: `${base}/general-manager/delivery-services`,
	dirverifypay: `${base}/general-manager/verify-payment`,
	resrvgetcarts: `${base}/general-manager/reservation-carts`,
	resrventry: `${base}/general-manager/reservation-entry`,
	resrvgetcartdet: `${base}/general-manager/reservation-cart-details`,
	reservaddtocart: `${base}/general-manager/reservation-add-to-cart`,
	reservupdsit: `${base}/general-manager/update-siting-cart`,
	reservdelcartitem: `${base}/general-manager/reservation-delete-cart-item`,
	reservupcartqty: `${base}/general-manager/reservation-update-cart-qty`,
	reservaddxtrachar: `${base}/general-manager/reservation-extra-charges`,
	resrvverifypay: `${base}/general-manager/reservation-verify-payment`,
	printers: `${base}/general-manager/printers`,
	printer: `${base}/general-manager/printer`,
	pauseors: `${base}/general-manager/pause-start-print`,
	default: `${base}/general-manager/default-printer`,
	printstate: `${base}/general-manager/printer-status`,
	settings: `${base}/general-manager/settings`,
	updatesettings: `${base}/general-manager/update-settings`,
	logout: `${base}/general-manager/logout`,
	template: `${base}/general-manager/bill-template`,
	directprint: `${base}/general-manager/direct-print`,
	localonprint: '/on-print',
	attendance: `${base}/general-manager/attendance`,
};

export default endpoints;
