import React, {useEffect, useRef, useState} from 'react';
import './CouponsOwner.css';
import Space from '../../components/Space/Space';
import ListView, {type ListViewHandle} from '../../components/ListView/ListView';
import {type RestaurantModel} from '../../models/restaurants';
import RestaurantsService from '../../services/restaurants';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import CouponsService from '../../services/coupons';
import {type ResCoupons} from '../../models/coupons';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import CheckBox from '../../components/CheckBox/CheckBox';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import DateTimeFrto, {type DateTimeFrtoHandle} from '../../components/DateTimeFrto/DateTimeFrto';
import RadioButton from '../../components/RadioButton/RadioButton';
import {type ConditionModel} from '../../models/condition';
import ConditionsService from '../../services/conditions';
import Button from '../../components/Button/Button';

type Fields = {
	id: number;
	label: string;
};

const CouponsOwner = () => {
	const snackBarRibbon = useRef<SnackRibbonHandle>(null);

	const [isRestLoading, setRestIsLoading] = useState<boolean>(true);
	const [restData, setRestData] = useState<RestaurantModel[]>([]);
	const [selRest, setSelRest] = useState<number>(-1);

	const [isCoupLoading, setCoupIsLoading] = useState<boolean>(true);
	const [coupData, setCoupData] = useState<ResCoupons[]>([]);
	const [selCoup, setSelCoup] = useState<number>(-1);

	const [isAddCoup, setAddCoup] = useState<boolean>(false);
	const [isAddLoading, setAddIsLoading] = useState<boolean>(false);
	const [selServ, setSelServ] = useState<number[]>([]);
	const servsRef = useRef<ListViewHandle>(null);
	const nameField = useRef<TextFieldHandle>(null);
	const noteField = useRef<TextFieldHandle>(null);
	const [isPercentage, setIsPercentage] = useState<boolean>(false);
	const disPriField = useRef<TextFieldHandle>(null);
	const codeField = useRef<TextFieldHandle>(null);
	const coupValiRef = useRef<DateTimeFrtoHandle>(null);
	const [isOnePerUser, setOnePerUser] = useState<boolean>(false);
	const [isCondLoading, setCondLoading] = useState<boolean>(true);
	const [fields, setFields] = useState<Fields[]>([]);
	const [condData, setCondData] = useState<ConditionModel[]>([]);
	const [selCondIndex, setSelCondIndex] = useState<number>(-1);
	const fieldsRef = Array.from({length: 10}, () => useRef<TextFieldHandle>(null));

	useEffect(() => {
		void fetchRests();
		void fetchCond();
	}, []);

	// DATA --->

	async function fetchRests() {
		if (!isRestLoading) {
			setRestIsLoading(true);
		}

		await new RestaurantsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setRestData(val.res!.data!);
			}
		}).finally(() => {
			setRestIsLoading(false);
		});
	}

	async function getCoupons(restIndex: number, reset: boolean) {
		if (restIndex !== -1) {
			if (!isCoupLoading) {
				setCoupIsLoading(true);
			}

			await new CouponsService().get(restData[restIndex]._id).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					if (reset) {
						clearOnEventChanged(1);
					}

					setCoupData(val.res!.data!);
				}
			}).finally(() => {
				setCoupIsLoading(false);
			});
		}
	}

	async function fetchCond() {
		if (!isCondLoading) {
			setCondLoading(true);
		}

		await new ConditionsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setCondData(val.res!.data!);
			}
		}).finally(() => {
			setCondLoading(false);
		});
	}

	async function addCoupon() {
		const freeShipObjects: Record<string, unknown> = {};
		const objectValid = [];

		if (selCondIndex !== -1) {
			const schLen = Object.keys(condData[selCondIndex].schema);
			// Verify schema data
			for (let i = 0; i < schLen.length; i++) {
				const field = fieldsRef[i].current!.validate();
				if (!field.isValid) {
					snackBarRibbon.current!.trigger(true, field.msg);
				}

				if (field.isValid) {
					freeShipObjects[schLen[i]] = Number(fieldsRef[i].current!.getValue());
					objectValid.push(0);
				}
			}
		}

		if (selServ.length === 0) {
			snackBarRibbon.current!.trigger(true, 'Select atleast a service');
		} else if (!nameField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, nameField.current!.validate().msg);
		} else if (!noteField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, noteField.current!.validate().msg);
		} else if (!disPriField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, disPriField.current!.validate().msg);
		} else if (selCondIndex === -1) {
			snackBarRibbon.current!.trigger(true, 'Select a condition for the coupon');
		} else if (objectValid.length !== Object.keys(condData[selCondIndex].schema).length) {
			snackBarRibbon.current!.trigger(true, 'Condition object must be valid');
		} else if (!codeField.current!.validate().isValid) {
			snackBarRibbon.current!.trigger(true, codeField.current!.validate().msg);
		} else if (coupValiRef.current!.getValue() === undefined) {
			snackBarRibbon.current!.trigger(true, 'Select coupon validity');
		} else {
			if (!isAddLoading) {
				setAddIsLoading(true);
			}

			await new CouponsService().create({
				restId: restData[selRest]._id,
				restTypeIds: restData[selRest].restTypes.filter((_, i) => selServ.includes(i)).map(v => v._id),
				name: nameField.current!.getValue(),
				note: noteField.current!.getValue(),
				deductedPrice: parseFloat(disPriField.current!.getValue()),
				isPercentage,
				code: codeField.current!.getValue(),
				validity: coupValiRef.current!.getValue()!,
				isOnePerUser,
				conditionId: condData[selCondIndex]._id,
				conditionValue: freeShipObjects,
			}).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					setSelServ([]);
					nameField.current!.clearInput();
					noteField.current!.clearInput();
					disPriField.current!.clearInput();
					codeField.current!.clearInput();
					coupValiRef.current!.setValue('');
					setSelCondIndex(-1);
					for (let i = 0; i < objectValid.length; i++) {
						fieldsRef[i].current!.clearInput();
					}

					void getCoupons(selRest, false);
				}
			}).finally(() => {
				setAddIsLoading(false);
			});
		}
	}

	// FUNCTIONS --->

	const clearOnEventChanged = (i: number) => {
		if (i === 1) {
			// Reset while fetch coupons
			setSelCondIndex(-1);
			setSelServ([]);
			setSelCoup(-1);
			setAddCoup(false);
		}
	};

	const onPrepareFields = (j: number) => {
		const schemLen = Object.keys(condData[j].schema);

		const fie: Fields[] = [];
		for (let i = 0; i < schemLen.length; i++) {
			fie.push({id: i, label: schemLen[i]});
		}

		setFields(fie);
	};

	const loadGroupFetch = !isRestLoading && isCoupLoading;
	const loadGroupDetails = !isRestLoading && !isCoupLoading && selRest !== -1 && selCoup !== -1;
	const loadGroupAdd = selRest !== -1 && !isRestLoading && isAddCoup;

	// PAGES --->

	const condPage = () =>
		<div>
			<div>
				<Space size={20} isAutoResize={false}></Space>
				<label className='deliveryCharge-list-adapter-perc'>Fill the below paramaters for set the condition for this coupon</label>
				{selCondIndex !== -1 && <Space size={10} isAutoResize={false}></Space>}
				{selCondIndex !== -1 && <div className='deliveryCharge-cond-field-list' style={{width: '100%'}}>
					<ListView data={fields} adapter={function (i, item): JSX.Element {
						const itemData = item as Fields;
						return <div className='deliveryCharge-list-adapter'>
							<label className='deliveryCharge-list-adapter-ti-label'>{fields[i].label}</label>
							<Space size={5} isAutoResize={true}></Space>
							<TextField ref={fieldsRef[i]} isLoading={isCondLoading || isAddLoading}
								label={`Enter the ${fields[i].label}`}
								inputType={TextFieldInputTypes.floatnumber} options={{maxLength: 5}}></TextField>
						</div>;
					}} adapterHeight={55} />
				</div>}
			</div>
			<Space size={25} isAutoResize={false}></Space>
			<div className='deliveryCharge-cond-list' style={{width: '100%'}}>
				{isCondLoading ? (<div className='deliveryCharge-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='deliveryCharge-list-adapter'>
							<label className='deliveryCharge-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='deliveryCharge-list-adapter-card'>
								<label className='deliveryCharge-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='deliveryCharge-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={60} />
				</div>) : (<div className='deliveryCharge-left-list'>
					<ListView data={condData} adapter={function (i, item): JSX.Element {
						const itemData = item as ConditionModel;
						return <div className='deliveryCharge-list-adapter'>
							<label className='deliveryCharge-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='deliveryCharge-list-adapter-card' style={{width: '100%'}}>
								<label className='deliveryCharge-list-adapter-perc'>{itemData.name}</label>
							</div>
							<RadioButton title='' index={i} selIndex={selCondIndex} onClick={i => {
								if (!isAddLoading) {
									setSelCondIndex(i);
									onPrepareFields(i);
								}
							}}></RadioButton>
							<Space size={5} isAutoResize={true}></Space>
						</div>;
					}} selItemIndex={selCondIndex} onSelectItem={i => {
						if (!isAddLoading) {
							setSelCondIndex(i);
							onPrepareFields(i);
						}
					}} adapterHeight={60} />
				</div>)}
			</div>
		</div>;

	return (
		<div className='Coupons'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='Coupons-main'>
				<div className='coupons-main-left-container' style={{width: '20%'}}>
					{isRestLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='coupons-main-listview'>
							<label className='coupons-main-listitem-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='coupons-main-listitem-adapter-card'>
								<label className='coupons-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='coupons-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={restData} adapter={function (i, item): JSX.Element {
						const itemData = item as RestaurantModel;
						return <div className='coupons-main-listview'>
							<label className='coupons-main-listitem-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='coupons-main-listitem-adapter-card'>
								<label className='coupons-main-listitem-adapter-title'>{itemData.name}</label>
								<Space size={5} isAutoResize={true}></Space>
								<label className='coupons-main-listitem-adapter-subtitle'>{itemData.address}</label>
							</div>
						</div>;
					}} onSelectItem={i => {
						if (selRest !== i) {
							setSelRest(i);
							void getCoupons(i, true);
						}
					}} selItemIndex={selRest} adapterHeight={80} />)}
				</div>
				<div className='coupons-main-left-container' style={{width: '20%', background: 'none'}}>
					<div className='coupons-main-container-column'>
						{selRest !== -1 && <Space size={10} isAutoResize={false}></Space>}
						{selRest !== -1 && <OutlineButton onClick={function (): void {
							setAddCoup(true);
						}} label={'Add Coupon'} width='90%'></OutlineButton>}
						{selRest !== -1 && <Space size={10} isAutoResize={false}></Space>}
						<div style={{width: '100%', borderTop: '1px solid rgb(214, 223, 231)'}}>
							{selRest !== -1 && (loadGroupFetch ? <ListView dummy={5} adapter={function (i, item): JSX.Element {
								return <div className='coupons-main-listview'>
									<label className='coupons-main-listitem-adapter-index-load'>00</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='coupons-main-listitem-adapter-card'>
										<label className='coupons-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
									</div>
								</div>;
							}} selItemIndex={-1} adapterHeight={50} /> : <ListView data={coupData} adapter={function (i, item): JSX.Element {
								const itemData = item as ResCoupons;
								return <div className='coupons-main-listview'>
									<label className='coupons-main-listitem-adapter-index'>{i + 1}</label>
									<Space size={5} isAutoResize={true}></Space>
									<div className='coupons-main-listitem-adapter-card'>
										<label className='coupons-main-listitem-adapter-title'>{itemData.name}</label>
										<Space size={5} isAutoResize={true}></Space>
										<label className='coupons-main-listitem-adapter-subtitle'>{itemData.note}</label>
									</div>
								</div>;
							}} onSelectItem={i => {
								setAddCoup(false);
								setSelCoup(i);
							}} selItemIndex={selCoup} adapterHeight={50} />)}
						</div>
					</div>
				</div>
				{loadGroupAdd ? <div className='coupons-main-right-container'>
					<Button onClick={function (): void {
						void addCoupon();
					}} label={'Save'} isLoading={isAddLoading}></Button>
					<Space size={20} isAutoResize={false}></Space>
					<label className='coupons-main-listitem-adapter-title'>Services</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='coupons-main-listitem-adapter-subtitle'>select the available services for this coupon</label>
					<Space size={10} isAutoResize={false}></Space>
					<div className='listview-wrapper'>
						<ListView ref={servsRef} data={restData[selRest].restTypes} adapter={function (i: number, item: {_id: string; name: string} | number): JSX.Element {
							const itemData = item as {_id: string; name: string};
							return <div className='listview-item-adapter'>
								<label className='listview-item-adapter-index'>{i + 1}</label>
								<Space size={5} isAutoResize={true}></Space>
								<div className='listview-item-adapter-card'>
									<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
									<CheckBox title='' initVal={selServ.includes(i)} ovrVal={selServ.includes(i)} onClick={(b: boolean) => {
										if (!isAddLoading) {
											if (selServ.includes(i)) {
												setSelServ(selServ.filter(v => v !== i));
											} else {
												setSelServ([...selServ, i]);
											}
										}
									}}></CheckBox>
									<Space size={5} isAutoResize={true}></Space>
								</div>
							</div>;
						}} adapterHeight={35} selItemIndex={selServ} onSelectItem={(i: number) => {
							if (!isAddLoading) {
								if (selServ.includes(i)) {
									setSelServ(selServ.filter(v => v !== i));
								} else {
									setSelServ([...selServ, i]);
								}
							}
						}} />
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<div className='coupons-main-container-row'>
						<TextField ref={nameField} isLoading={isAddLoading}
							label={'Coupon Name'} styles={TextFieldStyles.indiTitle}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={10} isAutoResize={false}></Space>
						<TextField ref={noteField} isLoading={isAddLoading}
							label={'Coupon Note'} styles={TextFieldStyles.indiTitle}
							inputType={TextFieldInputTypes.name}></TextField>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<CheckBox title={'Is percentage?'} initVal={isPercentage} isLoading={isAddLoading} onClick={(b: boolean) => {
						setIsPercentage(isPercentage);
					}}></CheckBox>
					<Space size={15} isAutoResize={false}></Space>
					<div className='coupons-main-container-row'>
						<TextField ref={disPriField} isLoading={isAddLoading}
							label={'Discount Price'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 20.5'
							inputType={TextFieldInputTypes.floatnumber}></TextField>
						<Space size={15} isAutoResize={false}></Space>
						<TextField ref={codeField} isLoading={isAddLoading}
							label={'Code (6 char only)'} styles={TextFieldStyles.indiTitle} placeholder='I.e: AWSX00923'
							inputType={TextFieldInputTypes.name} options={{maxLength: 6}}></TextField>
					</div>
					<Space size={15} isAutoResize={false}></Space>
					<label className='AddFood-label'>Validity</label>
					<Space size={5} isAutoResize={false}></Space>
					<DateTimeFrto ref={coupValiRef} isLoading={isAddLoading}></DateTimeFrto>
					<Space size={15} isAutoResize={false}></Space>
					<CheckBox title={'Is one per user?'} initVal={isOnePerUser} isLoading={isAddLoading} onClick={(b: boolean) => {
						setOnePerUser(isOnePerUser);
					}}></CheckBox>
					<Space size={5} isAutoResize={false}></Space>
					<label className='coupons-main-listitem-adapter-subtitle'>coupon will only once can use each user</label>
					<Space size={15} isAutoResize={false}></Space>
					{condPage()}
					<Space size={50} isAutoResize={false}></Space>
				</div>
					: <div className='coupons-main-right-container'>
						{loadGroupDetails ? <div className='coupons-main-right-container-cont'>
							<Space size={40} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`Name : ${coupData[selCoup].name}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`Note : ${coupData[selCoup].note}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`Code : ${coupData[selCoup].code}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`Deduct : ${coupData[selCoup].isPercentage ? `${coupData[selCoup].deductedPrice}%` : coupData[selCoup].deductedPrice}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{coupData[selCoup].isOnePerUser ? 'Only once can use this code per user' : ''}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`For only services : ${coupData[selCoup].restTypes.map(v => v.name).join(', ')}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`Validity : ${coupData[selCoup].validity}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`Condition : ${coupData[selCoup].condition.name}`}</label>
							<Space size={5} isAutoResize={false}></Space>
							<label className='coupons-main-listitem-adapter-title'>{`Condition value : ${Object.entries(coupData[selCoup].conditionValue).map(([key, vue]) => `[${key}: ${vue as any}]`).join(', ')}`}</label>
						</div> : <div className='coupons-main-right-container-cont' style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
							<label className='coupons-main-listitem-adapter-subtitle' style={{textAlign: 'center'}}>Select any restaurant to get the coupons</label>
						</div>}
					</div>}
			</div>
		</div >
	);
};

export default CouponsOwner;
