import React, {useEffect, useRef, useState} from 'react';
import './Overrule.css';
import Space from '../../components/Space/Space';
import ListView from '../../components/ListView/ListView';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type AccessControlModel} from '../../models/overrule';
import OverruleService from '../../services/overrule';
import CheckBox from '../../components/CheckBox/CheckBox';
import RadioButton from '../../components/RadioButton/RadioButton';
import Button from '../../components/Button/Button';
import {type RestaurantModel} from '../../models/restaurants';
import RestaurantsService from '../../services/restaurants';
import LabelButton from '../../components/LabelButton/LabelButton';
import Changes from '../Changes/Changes';
import ChangesLog from '../ChangesLog/ChangesLog';
import Timekeeping from '../Timekeeping/Timekeeping';
import {usePluginContext} from '../../context/pluginContext';
import {RestaurantContext} from '../../context/restContext';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Overrule = () => {
	const plgin = usePluginContext();
	const [selMenuIndex, setMenuIndex] = useState<number>(1);

	const [restData, setRestData] = useState<RestaurantModel[]>([]);
	const [data, setData] = useState<AccessControlModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isLoading, setLoading] = useState<boolean>(true);
	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const [selAccess, setSelAccess] = useState<number>(1);
	const [selRests, setSelRests] = useState<number[]>([]);

	useEffect(() => {
		void fetchRest();
		void fetchData();
	}, []);

	async function fetchRest() {
		await new RestaurantsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setRestData(val.res!.data!);
			}
		});
	}

	async function fetchData() {
		if (!isLoading) {
			setLoading(true);
		}

		await new OverruleService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	async function updateApprove() {
		if (!isLoading) {
			setLoading(true);
		}

		await new OverruleService().update({
			accessId: data[selItemIndex]._id,
			assignedRestIds: selRests.length === 0 ? [] : restData.filter((_, i) => selRests.includes(i)).map(v => v._id),
			isFullAccess: selAccess === 0,
		}).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				void fetchData();
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	async function updateAccess() {
		if (!isLoading) {
			setLoading(true);
		}

		await new OverruleService().access({
			accessId: data[selItemIndex]._id,
			isApproved: !data[selItemIndex].isApproved,
		}).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				void fetchData();
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	async function onLinkOrUnlink(restId: string, isLink: boolean) {
		if (!isLoading) {
			setLoading(true);
		}

		await new OverruleService().lorul({
			accessId: data[selItemIndex]._id,
			restId,
			isLink,
		}).then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				void fetchData();
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': selItemIndex === -1 ? 'center' : 'start',
	};

	const approveUi = () => <div className='Overrule-container'>
		<div className='Overrule-container-row'>
			<label className='Overrule-detail-perc' style={{width: '100%'}}>Approve Access Request</label>
			<Button onClick={function (): void {
				void updateApprove();
			}} label={'Update'} isLoading={isLoading}></Button>
		</div>
		<Space size={20} isAutoResize={false}></Space>
		<label className='Overrule-detail-perc'>Giving full access to employee, they can add anything without confirmation</label>
		<Space size={10} isAutoResize={false}></Space>
		<div className='Overrule-container-row'>
			<RadioButton title='Full access?' onClick={c => {
				if (!isLoading) {
					setSelAccess(c);
				}
			}} index={0} selIndex={selAccess}></RadioButton>
			<Space size={15} isAutoResize={false}></Space>
			<RadioButton title='Limited access?' onClick={c => {
				if (!isLoading) {
					setSelAccess(c);
				}
			}} index={1} selIndex={selAccess}></RadioButton>
		</div>
		<Space size={10} isAutoResize={false}></Space>
		<div className='listview-wrapper' style={{height: '200px'}}>
			<ListView data={restData} adapter={function (i, item): JSX.Element {
				const itemData = item as RestaurantModel;
				return <div className='Overrule-list-adapter'>
					<label className='Overrule-list-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='Overrule-list-adapter-card' style={{width: '100%'}}>
						<label className='Overrule-list-adapter-perc'>{itemData.name}</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='Overrule-list-adapter-update'>{itemData.address}</label>
					</div>
					<CheckBox title={''} initVal={selRests.includes(i)} ovrVal={selRests.includes(i)} onClick={(b: boolean) => {
						if (!isLoading) {
							if (selRests.includes(i)) {
								setSelRests(prev => prev.filter(j => j !== i));
							} else {
								setSelRests([...selRests, i]);
							}
						}
					}}></CheckBox>
					<Space size={10} isAutoResize={false} ></Space>
				</div>;
			}} adapterHeight={60} selItemIndex={selRests} onSelectItem={(i: number) => {
				if (!isLoading) {
					if (selRests.includes(i)) {
						setSelRests(prev => prev.filter(j => j !== i));
					} else {
						setSelRests([...selRests, i]);
					}
				}
			}} />
		</div>
	</div>;

	// FUNCTIONS ---->

	const handleOnMenuClick = (i?: number) => {
		// Reset the data
		if (i === 1) {
			setItemIndex(-1);
			void fetchData();
		}

		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	return (
		<div className='Restaurants'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='rest-menu-bar'>
				<LabelButton onClick={handleOnMenuClick} index={1} selIndex={selMenuIndex} label={'Employees'}></LabelButton>
				{plgin.whiteList.isTimeKeepAllowed && <LabelButton onClick={handleOnMenuClick} index={2} selIndex={selMenuIndex} label={'Timekeeping'}></LabelButton>}
				<LabelButton onClick={handleOnMenuClick} index={3} selIndex={selMenuIndex} label={'Changes'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={4} selIndex={selMenuIndex} label={'Changes Log'}></LabelButton>
			</div>
			<div className='rest-menu-content'>{(() => {
				switch (selMenuIndex) {
					case 1:
						return <div className='Overrule'>
							<div className='Overrule-left-panel'>
								{isLoading ? (<div className='Overrule-left-list'>
									<ListView dummy={5} adapter={function (i, item): JSX.Element {
										return <div className='Overrule-list-adapter'>
											<label className='Overrule-list-adapter-index-load'>00</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='Overrule-list-adapter-card'>
												<label className='Overrule-list-adapter-update-load'>{'Tax Percentage'}</label>
											</div>
										</div>;
									}} selItemIndex={-1} adapterHeight={55} />
								</div>) : (<div className='Overrule-left-list'>
									<ListView data={data} adapter={function (i, item): JSX.Element {
										const itemData = item as AccessControlModel;
										return <div className='Overrule-list-adapter'>
											<label className='Overrule-list-adapter-index'>{i + 1}</label>
											<Space size={5} isAutoResize={true}></Space>
											<div className='Overrule-list-adapter-card'>
												{itemData.user !== undefined && <label className='Overrule-list-adapter-perc'>{itemData.user.firstName}</label>}
												<Space size={5} isAutoResize={false}></Space>
												<label className='Overrule-list-adapter-update'>{itemData.role.name}</label>
											</div>
										</div>;
									}} selItemIndex={selItemIndex} onSelectItem={i => {
										setItemIndex(i);
									}} adapterHeight={55} />
								</div>)}
							</div>
							<div className='Overrule-right-panel'>
								<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
								<div className='Overrule-right-content' style={customStyle}>
									{selItemIndex === -1 && <label className='Overrule-right-empty-content'>No items selected</label>}
									{selItemIndex !== -1 && <div>
										<div className='Overrule-container-row'>
											<label className='Overrule-detail-perc' style={{width: '80%'}}>{data[selItemIndex].user ? data[selItemIndex].user?.firstName : ''}</label>
											{data[selItemIndex].authorized && <Button onClick={function (): void {
												void updateAccess();
											}} label={data[selItemIndex].isApproved ? 'Disable Access' : 'Enable Access'} color={data[selItemIndex].isApproved ? 'red' : 'green'} isLoading={isLoading}></Button>}
										</div>
										<Space size={5} isAutoResize={false}></Space>
										{data[selItemIndex].user !== undefined && <label className='Overrule-detail-perc'>{data[selItemIndex].user ? data[selItemIndex].user?.lastName : ''}</label>}
										<Space size={10} isAutoResize={false}></Space>
										<label className='Overrule-detail-perc'>{data[selItemIndex].role.name}</label>
										<Space size={10} isAutoResize={false}></Space>
										<label className='Overrule-detail-perc'>{data[selItemIndex].isApproved ? 'Approved' : 'Not Approved'}</label>
										<Space size={10} isAutoResize={false}></Space>
										<label className='Overrule-detail-perc'>{data[selItemIndex].isFullAccess ? 'Full Access' : 'Limited Access'}</label>
										<Space size={10} isAutoResize={false}></Space>
										<label className='Overrule-detail-perc'>Restaurant linked</label>
										<Space size={5} isAutoResize={false}></Space>
										<div className='listview-wrapper' style={{height: '50%'}}>
											<RestaurantContext.Consumer>{rest => (<ListView data={rest.data} adapter={function (i, item): JSX.Element {
												const itemData = item as {_id: string; name: string; address: string};
												const isExi = data[selItemIndex].restaurants.map(v => v._id).includes(itemData._id);
												return <div className='Overrule-list-adapter'>
													<label className='Overrule-list-adapter-index'>{i + 1}</label>
													<Space size={5} isAutoResize={true}></Space>
													<div className='Overrule-list-adapter-card' style={{width: '100%'}}>
														<label className='Overrule-list-adapter-perc'>{itemData.name}</label>
														<Space size={5} isAutoResize={false}></Space>
														<label className='Overrule-list-adapter-update'>{itemData.address}</label>
													</div>
													<Space size={5} isAutoResize={true}></Space>
													<div className='Overrule-container-row' style={{justifyContent: 'center', alignItems: 'center'}}>
														<label className='Overrule-list-adapter-update'>{isExi ? 'Linked' : 'Not Linked'}</label>
														<Space size={10} isAutoResize={true}></Space>
														<Button onClick={
															() => {
																void onLinkOrUnlink(itemData._id, !isExi);
															}
														} color={isExi ? 'darkred' : 'green'} label={isExi ? 'Unlink' : 'Link'}></Button>
													</div>
													<Space size={5} isAutoResize={true}></Space>
												</div>;
											}} adapterHeight={60} />)}</RestaurantContext.Consumer>
										</div>
										<Space size={10} isAutoResize={false}></Space>
										{!data[selItemIndex].authorized && approveUi()}
										<Space size={50} isAutoResize={false}></Space>
									</div>}
								</div>
							</div>
						</div >;
					case 2:
						return plgin.whiteList.isTimeKeepAllowed ? <Timekeeping /> : <div />;
					case 3:
						return <Changes />;
					case 4:
						return <ChangesLog />;
					default:
						return <div />;
				}
			})()}</div>
		</div >
	);
};

export default Overrule;
