import React from 'react';
import './HomeOwner.css';
import {useAuthContext} from '../../context/authContext';
import Space from '../../components/Space/Space';
import {SocketContext} from '../../context/socketContext';

const HomeOwner = () => {
	const authRes = useAuthContext();

	return (
		<div className='HomeOwner'>
			<SocketContext.Consumer>
				{so => (<div className='HomeOwner-grid'>
					<div className='HomeOwner-grid-card' style={{backgroundColor: 'tomato'}}>
						<div className='HomeOwner-main-a'>
							<label className='text-h1'>Total</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='HomeOwner-main-b'>
							<label className='text-h2'>{so.analytics.total ?? 0}</label>
						</div>
					</div>
					<div className='HomeOwner-grid-card' style={{backgroundColor: 'cornflowerblue'}}>
						<div className='HomeOwner-main-a'>
							<label className='text-h1'>Placed</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='HomeOwner-main-b'>
							<label className='text-h2'>{so.analytics.placed ?? 0}</label>
						</div>
					</div>
					<div className='HomeOwner-grid-card' style={{backgroundColor: 'darkorange'}}>
						<div className='HomeOwner-main-a'>
							<label className='text-h1'>Packed</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='HomeOwner-main-b'>
							<label className='text-h2'>{so.analytics.packed ?? 0}</label>
						</div>
					</div>
					<div className='HomeOwner-grid-card' style={{backgroundColor: 'limegreen'}}>
						<div className='HomeOwner-main-a'>
							<label className='text-h1'>Shipped</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='HomeOwner-main-b'>
							<label className='text-h2'>{so.analytics.shipped ?? 0}</label>
						</div>
					</div>
					<div className='HomeOwner-grid-card' style={{backgroundColor: 'royalblue'}}>
						<div className='HomeOwner-main-a'>
							<label className='text-h1'>Revenue</label>
						</div>
						<Space size={8} isAutoResize={false}></Space>
						<div className='HomeOwner-main-b'>
							<label className='text-h2'>NZ${so.analytics.revenue ?? 0}</label>
						</div>
					</div>
				</div>)}
			</SocketContext.Consumer>
		</div >
	);
};

export default HomeOwner;

