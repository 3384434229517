import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';
import {type ResFood, type ReqFoodAvail, type ReqFoodStock, type ReqFoodOffer, type ReqFoodDelist} from '../models/foods';

type ServerMessage = {
	message: string;
};

class FoodsService {
	find = async (restId: string, mode: string, keyword: string): Promise<ServiceResponse<ResponseData<ResFood[]>>> => {
		try {
			const response = await axios.post<ResponseData<ResFood[]>>(endpoints.findfooditems,
				{restId, mode, keyword},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	get = async (restId: string): Promise<ServiceResponse<ResponseData<ResFood[]>>> => {
		try {
			const response = await axios.post<ResponseData<ResFood[]>>(endpoints.fooditems,
				{restId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	create = async (req: FormData): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.post<ResponseData<undefined>>(endpoints.fooditem,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'multipart/form-data',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	availability = async (req: ReqFoodAvail): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.foodavail,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	stock = async (req: ReqFoodStock): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.foodstock,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	offer = async (req: ReqFoodOffer): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.foodoffer,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	delist = async (req: ReqFoodDelist): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.patch<ResponseData<undefined>>(endpoints.fooddelist,
				req,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default FoodsService;
