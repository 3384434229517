import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';
import {type SitingsModel} from '../models/sitings';

type ServerMessage = {
	message: string;
};

class SitingsService {
	get = async (): Promise<ServiceResponse<ResponseData<SitingsModel[]>>> => {
		try {
			const response = await axios.get<ResponseData<SitingsModel[]>>(endpoints.sitings,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	getman = async (restId: string): Promise<ServiceResponse<ResponseData<SitingsModel[]>>> => {
		try {
			const response = await axios.post<ResponseData<SitingsModel[]>>(endpoints.sitings,
				{restId},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	create = async (name: string, hasCharge: boolean, amount: number, capacity: number): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.post<ResponseData<undefined>>(endpoints.siting,
				{
					name, hasCharge, amount, capacity,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	addsitings = async (restId: string, sitingIds: string[]): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.post<ResponseData<undefined>>(endpoints.linksitings,
				{
					restId, sitingIds,
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default SitingsService;
