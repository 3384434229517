import React, {useEffect, useRef, useState} from 'react';
import './Sitings.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import SitingsService from '../../services/sitings';
import {type SitingsModel} from '../../models/sitings';
import CheckBox, {type CheckBoxHandle} from '../../components/CheckBox/CheckBox';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Sitings = () => {
	const [isAddSit, setAddSit] = useState<boolean>(false);
	const [data, setData] = useState<SitingsModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const sitName = useRef<TextFieldHandle>(null);
	const hasCharge = useRef<CheckBoxHandle>(null);
	const amount = useRef<TextFieldHandle>(null);
	const capacity = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchSitings();
	}, []);

	async function fetchSitings() {
		await new SitingsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddSit(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = sitName.current!.validate();
		const field2 = amount.current!.validate();
		const field3 = capacity.current!.validate();

		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, field1.msg);
			onSaveBtn.current!.complete();
		}

		if (hasCharge.current!.getValue() && !field2.isValid) {
			snackBarRibbon.current!.trigger(true, field2.msg);
			onSaveBtn.current!.complete();
		}

		if (!field3.isValid) {
			snackBarRibbon.current!.trigger(true, field3.msg);
			onSaveBtn.current!.complete();
		}

		if (field1.isValid && field3.isValid && (hasCharge.current!.getValue() ? field2.isValid : true)) {
			setListLoading(true);
			await new SitingsService().create(sitName.current!.getValue()!, hasCharge.current!.getValue(), Number(amount.current!.getValue()!), Number(capacity.current!.getValue()!),
			).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchSitings();

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				sitName.current!.clearInput();
				if (hasCharge.current!.getValue()) {
					amount.current!.clearInput();
				}

				capacity.current!.clearInput();
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddSit) ? 'center' : 'start',
	};

	return (
		<div className='Sitings'>
			<div className='Sitings-left-panel'>
				<div className='Sitings-left-tools'>
					<label className='Sitings-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Siting'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='Sitings-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Sitings-list-adapter'>
							<label className='Sitings-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Sitings-list-adapter-card'>
								<label className='Sitings-list-adapter-perc-load'>{'Tax Percentage : ${}%'}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='Sitings-list-adapter-update-load'>{'Updated : ${}'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={50} />
				</div>) : (<div className='Sitings-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as SitingsModel;
						return <div className='Sitings-list-adapter'>
							<label className='Sitings-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Sitings-list-adapter-card'>
								<label className='Sitings-list-adapter-perc'>{itemData.name}</label>
								<Space size={10} isAutoResize={true}></Space>
								<label className='Sitings-list-adapter-update'>{itemData.hasCharge ? 'with charge' : 'no charge'}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddSit(false);
						setItemIndex(i);
					}} adapterHeight={50} />
				</div>)}
			</div>
			<div className='Sitings-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='Sitings-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddSit && <label className='Sitings-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='Sitings-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Sitings-detail-perc'>{data[selItemIndex].name}</label>
						<Space size={10} isAutoResize={true}></Space>
						<label className='Sitings-detail-update'>{data[selItemIndex].hasCharge ? 'with charge' : 'no charge'}</label>
						{data[selItemIndex].hasCharge && <Space size={10} isAutoResize={true}></Space>}
						{data[selItemIndex].hasCharge && <label className='Sitings-detail-update'>{`Charging amount : ${data[selItemIndex].amount}`}</label>}
						<Space size={10} isAutoResize={true}></Space>
						<label className='Sitings-detail-update'>{`Capacity : ${data[selItemIndex].capacity}`}</label>
					</div>}
					{isAddSit && <div>
						<TextField ref={sitName} isLoading={isListLoading}
							label={'Enter table or hall name'}
							inputType={TextFieldInputTypes.name} options={{maxLength: 200}}></TextField>
						<Space size={20} isAutoResize={false}></Space>
						<CheckBox ref={hasCharge} title={'Has charge?'}></CheckBox>
						<Space size={20} isAutoResize={false}></Space>
						<TextField ref={amount} isLoading={isListLoading}
							label={'Enter charging amount'}
							inputType={TextFieldInputTypes.number} options={{maxLength: 10}}></TextField>
						<Space size={20} isAutoResize={false}></Space>
						<TextField ref={capacity} isLoading={isListLoading}
							label={'Enter total capacity'}
							inputType={TextFieldInputTypes.number} options={{maxLength: 5}}></TextField>
						<Space size={20} isAutoResize={false}></Space>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Sitings;
