import React, {createContext, useContext, useEffect, useState} from 'react';
import {type PluginsMfModel} from '../models/plugins';
import PluginsService from '../services/plugins';
import {useAuthContext} from './authContext';

export type PluginContextResult = {
	data: PluginsMfModel[];
	isLoading: boolean;
	fetchData: () => Promise<void>;
	whiteList: {
		isSitAllowed: boolean;
		isInventoryAllowed: boolean;
		isTimeKeepAllowed: boolean;
		isLedgerAllowed: boolean;
	};
};

const PluginContext = createContext<PluginContextResult>({
	data: [], isLoading: true, async fetchData() {
		//
	},
	whiteList: {
		isSitAllowed: false,
		isInventoryAllowed: false,
		isTimeKeepAllowed: false,
		isLedgerAllowed: false,
	},
});

export function usePluginContext(): PluginContextResult {
	return useContext(PluginContext);
}

export function PluginProvider({children}: {children: JSX.Element}) {
	const authRes = useAuthContext();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<PluginsMfModel[]>([]);

	const [isSitAllowed, setSitAllowed] = useState<boolean>(false);
	const [isInventoryAllowed, setInventoryAllowed] = useState<boolean>(false);
	const [isTimeKeepAllowed, setTimeKeepAllowed] = useState<boolean>(false);
	const [isLedgerAllowed, setLedgerAllowed] = useState<boolean>(false);

	useEffect(() => {
		const fetchData = async () => {
			await getPlugins();
		};

		void fetchData();
	}, [authRes.restData, authRes.selRestRef]);

	const getPlugins = async () => {
		if (!isLoading) {
			setLoading(true);
		}

		await new PluginsService().get().then(val => {
			if (!val.hasError && data !== undefined) {
				onGetPlugin(val.res!.data);
			}
		}).finally(() => {
			setLoading(false);
		});
	};

	const onGetPlugin = (data: PluginsMfModel[] | undefined) => {
		setData(data!);
		verifyStatusOwner(data!);
	};

	const fetchData = async () => {
		await getPlugins();
	};

	const verifyStatusOwner = (data: PluginsMfModel[]) => {
		for (const d of data) {
			switch (d.name) {
				case 'Dining':
					setSitAllowed(true);
					break;
				case 'Ingredient Inventory':
					setInventoryAllowed(true);
					break;
				case 'Timekeeping':
					setTimeKeepAllowed(true);
					break;
				case 'Ledger':
					setLedgerAllowed(true);
					break;
				default:
					break;
			}
		}
	};

	return (
		<PluginContext.Provider value={{
			data,
			isLoading, fetchData,
			whiteList: {
				isSitAllowed,
				isInventoryAllowed,
				isTimeKeepAllowed,
				isLedgerAllowed,
			},
		}}>
			{children}
		</PluginContext.Provider>
	);
}

